import React, { useEffect, useReducer, useState } from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { InferType } from 'yup'
import Compressor from 'compressorjs'
import { observer } from 'mobx-react-lite'
import { SnapshotOut } from 'mobx-state-tree'
import { saveAs } from 'file-saver'
import { societyCreatePostSchema } from '../../../forms/schemas/society_create_post'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { Button } from '../../common/Button'
import { Dropdown } from '../../common/Dropdown'
import { Icon, IconChoices } from '../../common/Icon'
import { useToastNotifications } from '../../../hooks/useToastNotification'
import { ToastType } from '../../common/Toast/toast-type'
import { imageUploadReducer } from '../../../reducers/image-upload'
import { ProcessedImage, ProcessedMedia } from '../../../types/image-upload'
import { convertBase64, createUrl } from '../../../api/helpers'
import { ImageActions } from '../../../actions/image-upload'
import { useStores } from '../../../hooks/useStores'
import { theme } from '../../../theme/theme'
import { PostThumbnails } from './PostThumbnails'
import { formatFileSize } from '../../../helpers/number'
import { documentUploadReducer } from '../../../reducers/document-upload'
import { DocumentActions } from '../../../actions/document-upload'
import {
  ProcessedDocument,
  ProcessedFile,
} from '../../../types/document-upload'
import { EventPlanner } from './EventPlanner'
import { Thumbnail } from './Thumbnail'
import { PollEditor } from './PollEditor'
import { PostModel } from '../../../state/models/post'
import { DocumentSectionType } from '../../../state/models/document'
import { toBase64 } from '../../common/DropZone/helpers'
import { ImportantCheckbox } from '../ImportantCheckbox'
import { FormatTextPopover } from '../CreateEditPostModal/CreateEditPostModalContent/FormatTextPopover'
import { isImageType } from '../../../helpers/types'

interface AttachmentsContainerProps {
  control: Control<InferType<typeof societyCreatePostSchema>>
  setValue: UseFormSetValue<InferType<typeof societyCreatePostSchema>>
  setLoadingAttachment: React.Dispatch<React.SetStateAction<boolean>>
  watchSociety: string
  watchEventsIds: (string | undefined)[] | undefined
  watchPollIds: (string | undefined)[] | undefined
  watchRole: string
  watchIsAlert: boolean | undefined
  post: SnapshotOut<typeof PostModel> | undefined
}

export const AttachmentsContainer = observer(
  ({
    control,
    setValue,
    setLoadingAttachment,
    watchSociety,
    watchEventsIds,
    watchPollIds,
    watchRole,
    watchIsAlert,
    post,
  }: AttachmentsContainerProps): JSX.Element => {
    const { documentStore, mediaStore, eventStore, pollStore } = useStores()
    const { translate } = useAppTranslation()
    const { setToastNotification } = useToastNotifications()
    const [imagesState, imagesDispatch] = useReducer(imageUploadReducer, {
      images: post?.media
        ? post.media
            .map((_media) => mediaStore.media.get(_media))
            .filter((_media) => _media !== undefined)
            .map((media) => ({
              id: media?._id as string,
              uploading: false,
              cancelled: false as const,
              uri: media?.url as string,
            }))
        : [],
    })

    const [documentsState, documentsDispatch] = useReducer(
      documentUploadReducer,
      {
        documents: post?.documentsIds
          ? post.documentsIds.map((_id) => {
              const _document = documentStore.documents.get(_id)
              return {
                id: _document?._id as string,
                uploading: false,
                name: _document?.name as string,
                uri: _document?.filename as string,
              }
            })
          : [],
      }
    )

    const [displayEvent, setDisplayEvent] = useState<boolean>(false)
    const [displayPoll, setDisplayPoll] = useState<boolean>(false)

    const [editPollId, setEditPollId] = useState<undefined | string>(undefined)
    const [editEventId, setEditEventId] = useState<undefined | string>(
      undefined
    )

    const maxImageUploadLimit = 10
    const maxDocumentUploadLimit = 10

    useEffect(() => {
      setValue(
        'media',
        imagesState.images
          .map((_image) => _image.id)
          .filter((_id) => _id !== undefined),
        { shouldValidate: true, shouldDirty: true }
      )
    }, [imagesState, setValue])

    useEffect(() => {
      setValue(
        'documentsIds',
        documentsState.documents
          .map((_document) => _document.id)
          .filter((_id) => _id !== undefined),
        { shouldValidate: true, shouldDirty: true }
      )
    }, [documentsState, setValue])

    const checkImageUploading = (images: ProcessedMedia[]): boolean => {
      return images.map((image) => image.uploading).includes(true)
    }

    const checkDocumentUploading = (documents: ProcessedFile[]): boolean => {
      return documents.map((document) => document.uploading).includes(true)
    }

    const imageUploading = checkImageUploading(imagesState.images)

    const documentUploading = checkDocumentUploading(documentsState.documents)

    useEffect(() => {
      setLoadingAttachment(imageUploading || documentUploading)
    }, [documentUploading, imageUploading, setLoadingAttachment])

    const hasImageLimitExceeded = (imagesLength: number): boolean => {
      return (
        imagesLength > maxImageUploadLimit ||
        imagesState.images.length + imagesLength > maxImageUploadLimit
      )
    }

    const hasDocumentLimitExceeded = (documentLength: number): boolean => {
      return (
        documentLength > maxDocumentUploadLimit ||
        documentsState.documents.length + documentLength >
          maxDocumentUploadLimit
      )
    }

    const uploadImage = async (
      e: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
      const images = e.target.files && e.target.files
      if (!images) {
        return
      }
      if (hasImageLimitExceeded(images.length)) {
        setToastNotification(
          ToastType.DANGER,
          translate('chatUpload.flashMessage.uploadImageLimitReached')
        )
      } else {
        Array.from(images).forEach((image: File) => {
          if (!isImageType(image.type)) {
            setToastNotification(
              ToastType.DANGER,
              translate('uploadImage.invalidImageType')
            )
            return
          }
          // eslint-disable-next-line no-new
          new Compressor(image, {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
            success: async (result) => {
              const _image: ProcessedImage = {
                uri: URL.createObjectURL(image),
                name: image.name,
                size: formatFileSize(image.size),
              }

              imagesDispatch({ type: ImageActions.ADD, image: _image })

              const base64 = await convertBase64(result)
              const id = await mediaStore.createMedia('image', base64 as string)

              imagesDispatch({
                type: ImageActions.SET_UPLOADED,
                mediaId: id as string,
                image: _image,
              })
            },
          })
        })
      }
      e.target.value = ''
    }

    const uploadDocument = async (
      e: React.ChangeEvent<HTMLInputElement>
    ): Promise<void> => {
      const documents = e.target.files && e.target.files
      if (!documents) {
        return
      }
      if (hasDocumentLimitExceeded(documents.length)) {
        setToastNotification(
          ToastType.DANGER,
          translate('chatUpload.flashMessage.uploadDocumentLimitReached')
        )
      } else {
        Array.from(documents).map(async (document: File): Promise<void> => {
          const { name } = document
          const _document: ProcessedDocument = {
            uri: URL.createObjectURL(document),
            name,
            size: formatFileSize(document.size),
            icon: IconChoices.DOCUMENT,
          }
          documentsDispatch({ type: DocumentActions.ADD, document: _document })

          const { base64 } = await toBase64(document)

          const documentId = await documentStore.createDocument(
            document.type,
            base64,
            name,
            name,
            undefined,
            watchSociety,
            'feed' as unknown as typeof DocumentSectionType
          )

          documentsDispatch({
            type: DocumentActions.SET_UPLOADED,
            id: documentId as string,
            document: _document,
          })
        })
      }
      e.target.value = ''
    }

    const onImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      uploadImage(e)
    }

    const onDocumentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      uploadDocument(e)
    }

    const onImageRemove = (id: string): void => {
      imagesDispatch({ type: ImageActions.REMOVE, id })
    }

    const onDocumentRemove = (id: string): void => {
      documentsDispatch({
        type: DocumentActions.REMOVE,
        id,
      })
    }

    const onEventRemove = (id: string): void => {
      setValue(
        'eventsIds',
        watchEventsIds?.filter((_id) => _id !== id),
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      )
      setDisplayEvent(false)
    }

    const onPollRemove = (id: string): void => {
      setValue(
        'pollIds',
        watchPollIds?.filter((_id) => _id !== id),
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      )
      setDisplayPoll(false)
    }

    const onPollThumbnailClick = (id: string): void => {
      setEditPollId(id)
      setDisplayPoll(true)
    }

    const onEventThumbnailClick = (id: string): void => {
      setEditEventId(id)
      setDisplayEvent(true)
    }

    const hasUniqueItem =
      (watchPollIds && watchPollIds.length > 0) ||
      (documentsState.documents && documentsState.documents.length > 0) ||
      (watchEventsIds && watchEventsIds.length > 0)

    const dropdownOptions = [
      {
        value: 'pictures',
        label: translate('postCreateUpdateBase.attachments.pictures'),
        customContent: (
          <div
            className={`flex ${watchRole !== 'resident' ? 'px-4 pt-4' : ''}`}
          >
            <div className="flex w-full items-center">
              <label
                htmlFor="post-image-input"
                className="flex h-full w-full cursor-pointer items-center gap-3 rounded 
              p-3 hover:bg-neutral-96"
              >
                <Icon icon={IconChoices.IMAGE} />
                <span style={theme.textVariants.baseBold}>
                  {translate('postCreateUpdateBase.attachments.pictures')}
                </span>
              </label>
            </div>
          </div>
        ),
      },
      // {
      //   value: 'gallery',
      //   label: translate('postCreateUpdateBase.attachments.gallery'),
      //   icon: IconChoices.IMAGES,
      //   onClick: () => null,
      // },
      ...(watchRole !== 'resident'
        ? [
            {
              value: 'document',
              label: translate('postCreateUpdateBase.attachments.document'),
              disabled: hasUniqueItem,
              customContent: (
                <div className="flex px-4">
                  <div className="flex w-full items-center">
                    <label
                      htmlFor="post-document-input"
                      className="flex h-full w-full cursor-pointer items-center 
                    gap-3 rounded p-3 hover:bg-neutral-96"
                    >
                      <Icon icon={IconChoices.DOCUMENT_2} />
                      <span style={theme.textVariants.baseBold}>
                        {translate('postCreateUpdateBase.attachments.document')}
                      </span>
                    </label>
                  </div>
                </div>
              ),
            },
            {
              value: 'event',
              label: translate('postCreateUpdateBase.attachments.event'),
              icon: IconChoices.CALENDAR,
              disabled: hasUniqueItem,
              onClick: () => {
                setDisplayPoll(false)
                setDisplayEvent(true)
              },
            },
            {
              value: 'poll',
              label: translate('postCreateUpdateBase.attachments.poll'),
              icon: IconChoices.BAR_CHART,
              disabled: hasUniqueItem,
              onClick: () => {
                setDisplayEvent(false)
                setDisplayPoll(true)
              },
            },
          ]
        : []),
    ]

    const downloadDocument = (id: string, filename: string): void => {
      saveAs(createUrl(`/documents/fetch/${id}`), filename)
    }

    return (
      <div className="flex flex-col gap-6">
        <div className="flex">
          <Controller
            control={control}
            name="media"
            render={() => (
              <input
                id="post-image-input"
                type="file"
                accept="image/png, image/jpg, image/jpeg, image/svg"
                className="h-0 w-0 max-w-0 opacity-0"
                tabIndex={0}
                multiple
                onChange={onImageChange}
              />
            )}
          />
          <Controller
            control={control}
            name="documentsIds"
            render={() => (
              <input
                id="post-document-input"
                type="file"
                accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.key,.numbers,.pages,.xlsm"
                className="h-0 w-0 max-w-0 opacity-0"
                tabIndex={0}
                multiple
                onChange={onDocumentChange}
              />
            )}
          />
          <div className="flex flex-wrap gap-2">
            <Dropdown
              className="w-fit"
              dropdownPosition="bottom-right"
              options={dropdownOptions}
            >
              <Button
                label={translate('postCreateUpdateBase.media.attach')}
                iconPlacement="right"
                icon={IconChoices.PLUS_SIGN}
              />
            </Dropdown>
            <ImportantCheckbox
              control={control}
              watchRole={watchRole}
              watchIsAlert={!!watchIsAlert}
            />
            <FormatTextPopover />
          </div>
        </div>
        {imagesState.images.length > 0 && (
          <PostThumbnails
            header={translate('postCreateUpdateBase.media.images')}
          >
            {imagesState.images.map((image, index) => (
              <Thumbnail
                key={`${image.uri}${image.id}`}
                id={image.id as string}
                uri={image.uri}
                title={image.name || `Image-${index}`}
                description={image.size || 'Unknown file size'}
                index={index}
                onThumbnailRemove={onImageRemove}
                onThumbnailClick={() =>
                  window.open(image.uri, '_blank', 'noreferrer noopener')
                }
                loading={image.uploading}
              />
            ))}
          </PostThumbnails>
        )}
        {documentsState.documents.length > 0 && (
          <PostThumbnails
            header={translate('postCreateUpdateBase.media.documents')}
          >
            {documentsState.documents.map((doc, index) => (
              <Thumbnail
                key={`${doc.id}${doc.name}`}
                id={doc.id as string}
                icon={doc.icon}
                title={doc.name || `File-${index}`}
                description={doc.size || 'Unknown file size'}
                index={index}
                onThumbnailClick={() =>
                  downloadDocument(doc.id as string, doc.name)
                }
                onThumbnailRemove={onDocumentRemove}
                loading={doc.uploading}
              />
            ))}
          </PostThumbnails>
        )}
        {watchEventsIds && watchEventsIds.length > 0 && (
          <PostThumbnails
            header={translate('postCreateUpdateBase.attachments.event')}
          >
            {watchEventsIds.map((_id, index) => {
              const event = eventStore.events.get(_id as string)
              return (
                <>
                  {event && (
                    <Thumbnail
                      key={`${event._id}${event.title}`}
                      id={event._id as string}
                      icon={IconChoices.CALENDAR}
                      title={event.title}
                      description={event.location as string}
                      index={index}
                      onThumbnailRemove={onEventRemove}
                      onThumbnailClick={onEventThumbnailClick}
                    />
                  )}
                </>
              )
            })}
          </PostThumbnails>
        )}
        {watchPollIds && watchPollIds.length > 0 && (
          <PostThumbnails
            header={translate('postCreateUpdateBase.attachments.poll')}
          >
            {watchPollIds.map((_id, index) => {
              const poll = pollStore.polls.get(_id as string)
              if (poll) {
                return (
                  <Thumbnail
                    key={`${poll._id}${poll.title}`}
                    id={poll._id as string}
                    icon={IconChoices.DOCUMENT_2}
                    title={poll.title}
                    description={translate('pollEditor.pollDescription')}
                    index={index}
                    onThumbnailRemove={onPollRemove}
                    onThumbnailClick={onPollThumbnailClick}
                  />
                )
              }
              return <></>
            })}
          </PostThumbnails>
        )}
        {displayEvent && watchRole !== 'resident' && (
          <div className="flex flex-col gap-2">
            <p style={theme.textVariants.baseBold}>
              {translate('postCreateUpdateBase.attachments.event')}
            </p>
            <EventPlanner
              eventId={editEventId}
              setPostValue={setValue}
              setDisplayEvent={setDisplayEvent}
              watchSociety={watchSociety}
              watchEventsIds={watchEventsIds}
            />
          </div>
        )}
        {displayPoll && watchRole !== 'resident' && (
          <div className="flex flex-col gap-2">
            <p style={theme.textVariants.baseBold}>
              {translate('postCreateUpdateBase.attachments.poll')}
            </p>
            <PollEditor
              pollId={editPollId}
              setPostValue={setValue}
              setDisplayPoll={setDisplayPoll}
              watchSociety={watchSociety}
              watchPollIds={watchPollIds}
            />
          </div>
        )}
      </div>
    )
  }
)
