import React, { useCallback, useMemo } from 'react'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import { flatten } from '../../../helpers/array'
import { sortStringsAlphabetically } from '../../../helpers/sorting'
import { useAppTranslation } from '../../../hooks/useAppTranslation'
import { theme } from '../../../theme/theme'
import { Icon, IconChoices } from '../Icon'
import { SpinnerWrapper } from '../SpinnerWrapper'
import { toBase64 } from './helpers'

export enum DropZoneVariant {
  MEDIA = 0,
  DOCUMENT = 1,
  EXCEL = 2,
}

export interface DropZoneFile {
  base64: string
  name: string
  type: string
  uri: string
}

interface DropZoneProps {
  onUpload: (
    status: 'accepted' | 'error',
    file: DropZoneFile,
    errorCode?: ErrorCode | string
  ) => void
  errorMessage?: string
  maxSize?: number
  loading?: boolean
  multiple?: boolean
  variant?: DropZoneVariant
  className?: string
}

export const DropZone = ({
  onUpload,
  errorMessage,
  maxSize = 60 * 1000000, // 60 mb
  loading,
  multiple = false,
  variant = DropZoneVariant.DOCUMENT,
  className,
}: DropZoneProps): JSX.Element => {
  const { translate } = useAppTranslation()
  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejects: FileRejection[]) => {
      // handle rejections
      fileRejects.forEach((_reject) => {
        const _errorCode =
          _reject.errors.length > 0 ? _reject.errors[0].code : undefined
        onUpload(
          'error',
          {
            base64: '',
            name: _reject.file.name,
            type: _reject.file.type,
            uri: '',
          },
          _errorCode
        )
      })

      // handle accepted files
      const promises = acceptedFiles.map((_file) => toBase64(_file))
      const result = await Promise.allSettled(promises)
      const fulfilledPromises = result.filter(
        (_result) => _result.status === 'fulfilled'
      )

      fulfilledPromises.forEach((_result, index) => {
        const { type } = acceptedFiles[index]
        const uri = URL.createObjectURL(acceptedFiles[index])
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onUpload('accepted', { ..._result.value, type, uri })
      })
    },
    [onUpload]
  )

  const getAcceptedMimeTypes = (): { [key: string]: string[] } => {
    const imageMimeTypes = {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/webp': ['.webp'],
    }
    const excelMimeTypes = {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    }
    const documentMimeTypes = {
      ...excelMimeTypes,
      'application/pdf': ['.pdf'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        ['.pptx'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/zip': ['.zip'],
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/webp': ['.webp'],
      'image/svg': ['.svg'],
      'application/vnd.apple.pages': ['.pages'],
      'application/vnd.apple.keynote': ['.key'],
      'application/vnd.apple.numbers': ['.numbers'],
    }
    switch (variant) {
      case DropZoneVariant.MEDIA:
        return imageMimeTypes
      case DropZoneVariant.DOCUMENT:
        return documentMimeTypes
      case DropZoneVariant.EXCEL:
        return excelMimeTypes
      default:
        return {}
    }
  }

  const getAcceptedFileFormats = (): string[] => {
    return flatten(Object.values(getAcceptedMimeTypes()))
      .map((_format) => _format.slice(1))
      .sort((a, b) => sortStringsAlphabetically(a, b))
  }

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      multiple,
      maxSize,
      accept: getAcceptedMimeTypes(),
    })

  const dragClassNames = useMemo(
    () => `
    ${isDragAccept || isDragReject ? 'bg-gray-100' : ''}
    ${isDragAccept ? 'border-green' : ''}
    ${isDragReject ? 'border-red' : ''}
    `,
    [isDragAccept, isDragReject]
  )

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      className={`border-1 relative rounded-md border border-dashed bg-gray-50 p-6 md:p-10
      ${errorMessage ? 'border-red' : 'border-neutral-80'}
      ${dragClassNames}
      ${className ?? ''}`}
    >
      {loading && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
          <SpinnerWrapper wrapperClassName="items-center" />
        </div>
      )}
      <div
        className={`flex w-full flex-col items-center text-center ${
          loading ? 'opacity-0' : ''
        }`}
      >
        <input {...getInputProps()} />
        <Icon icon={IconChoices.CLOUD_UPLOAD} size={52} color="#999999" />
        <p style={theme.textVariants.baseBold}>
          {translate('dropZone.dragFileHere')}{' '}
          <span className="underline hover:cursor-pointer">
            {translate('dropZone.clickToSelect')}
          </span>
        </p>
        <p style={theme.textVariants.caption} className="mt-1 text-gray-500">
          {translate('dropZone.acceptedFormats')}:{' '}
          {getAcceptedFileFormats().join(', ').toUpperCase()}
        </p>
        {errorMessage ? (
          <p style={theme.textVariants.caption} className="mt-1 text-red">
            {errorMessage}
          </p>
        ) : null}
      </div>
    </div>
  )
}
